import React from 'react';

class Label extends React.Component {
  render() {
    //
    return (
      <div className="label">
      <svg viewBox="0 0 100 100">
        <circle id="label-circle" cx="50" cy="50" r="50" stroke="transparent"/>
        <path id="label-text-path" d="M 25 50 A 25 25 0 0 1 75 50" fill="transparent" stroke="transparent" transform="rotate(-45,50,50)"/>
        <text>
          <textPath id="label-text" xlinkHref="#label-text-path">
            {this.props.text}
          </textPath>
        </text>
</svg>
</div>
    )

  }
}

Label.defaultProps = {
  text: 'Work'
}

export default Label;