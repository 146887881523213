import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Label from '../components/Label';

import Superblock from '../components/Superblock';
import Leftblock from '../components/Leftblock';
import Rightblock from '../components/Rightblock';
import Bannerblock from '../components/Bannerblock';
import { graphql } from 'gatsby';
import ScrollingEff from "../components/ScrollingEff";
import { build_home_content } from '../utils/contentbuilder';

class IndexPage extends React.Component {

  render() {

    let contentNodes = [];
    if (this.props.data.allMarkdownRemark) {
      console.log('All Page data.');
      console.debug(this.props.data.allMarkdownRemark.edges);
    }
    
    let content = build_home_content(this.props.data.allMarkdownRemark.edges);
    console.log('Content for Index page');
    console.log(content);
    for (let i=0; i < content.length; i++) {
      let node = content[i].node;
      let meta = content[i].meta;

      let title = node.frontmatter.title;
      let abstract = node.frontmatter.abstract;
      let date = node.frontmatter.date;
      let path = node.frontmatter.path;
      let image = node.frontmatter.featureImage;

      if (meta.blocktype === 'superblock') {
        contentNodes.push(
          <Superblock key={path}
            headline={title}
            lead={abstract}
            date={date}
            link={path}
            />);
      }
      if (meta.blocktype === 'leftblock') {
        contentNodes.push(
          <Leftblock key={path}
            headline={title}
            lead={abstract}
            date={date}
            link={path}
            image_src={image}>
              {meta.label ?<Label text={meta.label}/>: null}
            </Leftblock>
            );
      }
      if (meta.blocktype === 'rightblock') {
        contentNodes.push(
          <Rightblock key={path}
            headline={title}
            lead={abstract}
            date={date}
            link={path}
            image_src={image}>
              {meta.label ?<Label text={meta.label}/>: null}
            </Rightblock>
            );
      }
      if (meta.blocktype === 'bannerblock') {
        let banner_link = node.frontmatter.link
        if (i === content.length - 1) {
          contentNodes.push(<Bannerblock content={abstract} key={abstract} link={banner_link} className="endblock" />);
        } else {
          contentNodes.push(<Bannerblock content={abstract} key={abstract} link={banner_link}  />);
        }
      }

    }
    
    return (
      <Layout>
      <SEO title="Fwends - Expeditions into the Digital" />
      <ScrollingEff />
      {contentNodes}

    </Layout>
    )
  }
}

IndexPage.defaultProps = {
  data: {
    allMarkdownRemark: {}
  }

}

export default IndexPage


export const pageQuery = graphql`
    query {
      allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]},
        filter: {frontmatter: {path: {regex: "/lets-be-fwends/|/projects/|/articles/"}}}
        ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            html
            frontmatter {
              date
              path
              featureImage
              title
              abstract
            }
          }
        }
      }
    }`
