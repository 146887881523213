import React from 'react';

import { formatDate } from '../utils/dates';
import {Container, Row, Col} from 'reactstrap';

import Link from '../net/Link';

/**
 * Superblock uses a larger headline with a different font compared to the sideblocks,
 * and does not have an image.
 * 
 * @class Superblock
 * @extends {React.Component}
 * 
 */
class Superblock extends React.Component {

  render() {
    let date = formatDate(this.props.date);
    
    return (
      <Container fluid={true} className="superblock block w-80">
        <Link to={this.props.link}>
        <Row>
          <Col sm="12">
            {this.props.children}
          
            <h1><span dangerouslySetInnerHTML={{__html: this.props.headline}}></span><span className='date'>{date}</span></h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6" className="left">
            <div className="lead" dangerouslySetInnerHTML={{__html:this.props.lead}} />
             
            <p><span className="readmore">Read more</span></p>
          </Col>
        </Row>
        </Link>
      </Container>
    )
  }

}

Superblock.defaultProps = {
  headline: '',
  date: '2017-11-20T07:00:00+00:00',
  lead: '',
  link: '/'
}

export default Superblock;