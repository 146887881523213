import React from 'react';

import Link from '../net/Link';

import { Container, Row, Col } from 'reactstrap';

class Bannerblock extends React.Component {


  render() {
    let className = "bannerblock block";
    if (this.props.className) {
      className += " " + this.props.className;
    }
    return (
      <Container fluid className={className}>
        <Container fluid className="w-80 px-0">
          <Row>
            <Col>
            <div dangerouslySetInnerHTML={{__html: this.props.content}} />
            {this.props.link ? 
            <p><Link to={this.props.link.target}>{this.props.link.title}</Link></p>
            :null }
            </Col>
          </Row>
        </Container>
      </Container>
    )
  }
}

Bannerblock.defaultProps = {
  content: '',
}

export default Bannerblock;